import { Link } from 'gatsby'
import React from 'react'
import Helmet from 'react-helmet'
import { Waypoint } from 'react-waypoint'
import Header from '../components/Header'
import Layout from '../components/layout'
import Nav from '../components/Nav'
import {
  SKILL_SET,
  CATEGORY,
  SKILL_LEVEL,
  SKILLS_BY_COMPANY,
} from '../assets/constants/skills'
import Pulse from 'react-reveal/Pulse'
import Zoom from 'react-reveal/Zoom'
import Flip from 'react-reveal/Flip'
import Slide from 'react-reveal/Slide'
import Fade from 'react-reveal/Slide'
import Scroll from '../components/Scroll'
import Avtar from '../assets/images/avatar.svg'
import Dekorate from '../assets/images/dekorate.png'
import Sprinklr from '../assets/images/sprinklr.png'
import Ecomi from '../assets/images/ecomi.jpg'
import Reveal from 'react-reveal/Reveal'
import config from 'react-reveal/globals'
import VRCardboard from '../assets/images/vr-solid.svg'
import IOT from '../assets/images/iot-solid.svg'
import Blockchain from '../assets/images/blockchain-solid.svg'
import Chat from '../assets/images/chat-solid.svg'
import Workflow from '../assets/images/workflow-solid.svg'
import Footer from '../components/Footer'
import Contact from '../components/Contact'
import AV_LOGO from '../assets/images/av_logo.png'

config({ ssrFadeout: true })
let simpleDialog = null

const getSkillsByCategory = category => {
  return SKILL_SET.filter(skill => {
    return skill.category === category
  })
}

const getSkillsBySkillLevel = (level, shouldShowInGraph) => {
  return SKILL_SET.filter(skill => {
    return skill.level === level && skill.showInGraph === shouldShowInGraph
  })
}

const renderIcon = skill => {
  return (
    <Zoom>
      <div className='tooltip'>
        <img
          className='skill-item'
          src={require(`../assets/skillIcons/${skill.icon}.svg`)}
        />
        <span class='tooltiptext'>{skill.name}</span>
      </div>
    </Zoom>
  )
}

const renderSkillItem = skill => {
  return (
    <div className='skill-label-container'>
      <span className='skill-label'>{skill.name}</span>
    </div>
  )
}

const renderCompanyDetails = ({ logo, title, skills }) => {
  return (
    <li className='company'>
      <div class='hovereffect'>
        <img class='img-responsive' src={logo} alt='' />
        {/* <div class='img-responsive' src={logo} alt=''>
          <img class='company-icon' src={logo} alt='' />
        </div> */}
        <div class='overlay'>
          <p>{title}</p>
          <ul class='skill-list'>
            {skills.map(skill => {
              return <li className='company-skill-item'>{skill}</li>
            })}
          </ul>
        </div>
      </div>
    </li>
  )
}

const renderSkillGroup = skills => {
  return <div className='skill-level-group'>{skills.map(renderSkillItem)}</div>
}

const renderHeaderTitle = (title, reveal = 'zoom') => {
  if (reveal == 'zoom') {
    return (
      <Zoom fraction={0.8}>
        <h2>{title}</h2>
      </Zoom>
    )
  } else if (reveal == 'fade') {
    return (
      <Fade left>
        <h2>{title}</h2>
      </Fade>
    )
  }
}

class Index extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      stickyNav: false,
      isContactVisible: false
    }
  }

  _handleWaypointEnter = () => {
    this.setState(() => ({ stickyNav: false }))
  }

  _handleWaypointLeave = () => {
    this.setState(() => ({ stickyNav: true }))
  }
  

  _showContactForm = () => {
    this.setState(() => ({ isContactVisible: true }))
  }

  _hideContactForm = () => {
    this.setState(() => ({ isContactVisible: false }))
  }

  render () {
    return (
      <Layout>
        <Helmet title='Arkit Vora'>
          <meta name="yandex-verification" content="32e05a6e716579ac" />
          <meta name="description" content={'I am a Software Developer who is passionate about meaningful designs and building rich experiences.'} />
          <meta name="image" content={AV_LOGO} />
          <meta name="google-site-verification" content="vyI4J5WzdpLpuf7y8ASwsoSiGGe6Eysl4UJBuk676pI" />
        </Helmet>
        
        <Header />

        <Waypoint
          onEnter={this._handleWaypointEnter}
          onLeave={this._handleWaypointLeave}
        ></Waypoint>
        <Nav sticky={this.state.stickyNav} />

        <div id='main'>
          <section id='about' className='main'>
            <div className='spotlight'>
              <div className='content'>
                <header className='major'>
                  {renderHeaderTitle('About me', 'fade')}
                </header>
                <p>
                  I am a Software Developer who is passionate about meaningful designs
                  and building rich experiences. Thus far in my career, I have
                  mentored, architected, lead, and produced many successful
                  features alongside a team of engineers as well as
                  independently.
                  <br />
                  <br />I have had the opportunity to build several production
                  ready applications using cutting edge technologies. I am
                  experienced and comfortable with MERN stack applications and
                  any isomorphic javascript applications. I have experience in
                  building hybrid mobile applications using the Ionic framework
                  as well as native mobile applications using React Native. I am
                  currently working on projects using React Native, ReactJS,
                  NodeJs, GraphQL and ES6
                </p>
              </div>

              <span className='image'>
                <img src={Avtar} alt='' />
              </span>
            </div>
          </section>

          <section id='skills' className='main special'>
            <header className='major'>
              {renderHeaderTitle('What I can do')}
            </header>
            {/* <ul className='features'>
              <li>
                <span className='icon major style1 fa-code'></span>
                <h3>Ipsum consequat</h3>
                <p>
                  Sed lorem amet ipsum dolor et amet nullam consequat a feugiat
                  consequat tempus veroeros sed consequat.
                </p>
              </li>
              <li>
                <span className='icon major style3 fa-copy'></span>
                <h3>Amed sed feugiat</h3>
                <p>
                  Sed lorem amet ipsum dolor et amet nullam consequat a feugiat
                  consequat tempus veroeros sed consequat.
                </p>
              </li>
              <li>
                <span className='icon major style5 fa-diamond'></span>
                <h3>Dolor nullam</h3>
                <p>
                  Sed lorem amet ipsum dolor et amet nullam consequat a feugiat
                  consequat tempus veroeros sed consequat.
                </p>
              </li>
            </ul> */}
            <div className='skill-container'>
              <div className='skills'>
                <div className='platforms'>
                  <div>
                    <h3>Mobile Applications</h3>
                    <div className='skill-group'>
                      <div className='skill-icon-container'>
                        {getSkillsByCategory(CATEGORY.MOBILE).map(skill => {
                          return renderIcon(skill)
                        })}
                      </div>
                    </div>
                  </div>

                  <div>
                    <h3>Web Applications</h3>
                    <div className='skill-group'>
                      <div className='skill-icon-container'>
                        {getSkillsByCategory(CATEGORY.WEB).map(skill => {
                          return renderIcon(skill)
                        })}
                      </div>
                    </div>
                  </div>

                  <div>
                    <h3>UI/UX</h3>
                    <div className='skill-group'>
                      <div className='skill-icon-container'>
                        {getSkillsByCategory(CATEGORY.DESIGN).map(skill => {
                          return renderIcon(skill)
                        })}
                      </div>
                    </div>
                  </div>

                  <div>
                    <h3>Miscellaneous</h3>
                    <div className='skill-group'>
                      <div className='skill-icon-container'>
                        {getSkillsByCategory(CATEGORY.MISC).map(skill => {
                          return renderIcon(skill)
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='skills graph'>
                {/* <Slide right delay={50}> */}
                {/* <Reveal effect='fadeInRight'> */}
                <div className='level-container'>
                  <div>
                    <div className='level beginner'>
                      {renderSkillGroup(
                        getSkillsBySkillLevel(SKILL_LEVEL.BEGINNER, true)
                      )}
                    </div>
                    <p className='skill-level-label'>Beginner</p>
                  </div>
                  <div>
                    <div className='level average'>
                      {renderSkillGroup(
                        getSkillsBySkillLevel(SKILL_LEVEL.AVERAGE, true)
                      )}
                    </div>
                    <p className='skill-level-label'>Proficient</p>
                  </div>
                  <div>
                    <div className='level advanced'>
                      {renderSkillGroup(
                        getSkillsBySkillLevel(SKILL_LEVEL.ADVANCED, true)
                      )}
                    </div>
                    <p className='skill-level-label'>Expert</p>
                  </div>
                </div>
                {/* </Slide> */}
                {/* </Reveal> */}
              </div>
            </div>
            {/* <footer className='major'>
              <ul className='actions'>
                <li>
                  <Scroll type='id' element='work'>
                    <div className='button'>Check Out My Work</div>
                  </Scroll>
                </li>
              </ul>
            </footer> */}
          </section>

          <section id='work' className='main special'>
            <header className='major'>
              {renderHeaderTitle('Companies I have worked with')}
              <p>
                I've worked remotely for agencies, consulted startups, and
                collaborated with talented people <br />
                to create digital products for both business and consumer use.{' '}
              </p>
            </header>
            <ul className='statistics'>
              {renderCompanyDetails({
                logo: Dekorate,
                title: 'Dekorate',
                skills: SKILLS_BY_COMPANY.DEKORATE,
              })}
              {renderCompanyDetails({
                logo: Sprinklr,
                title: 'Sprinklr',
                skills: SKILLS_BY_COMPANY.SPRINKLR,
              })}
              {renderCompanyDetails({
                logo: Ecomi,
                title: 'Ecomi',
                skills: SKILLS_BY_COMPANY.ECOMI,
              })}
            </ul>
          </section>
          <section id='domains' className='main special'>
            <header className='major'>
              {renderHeaderTitle('Technologies I have worked on')}
              <p>
                I specialize in building Mobile applications. <br />
                Over the past few years, I've built mobile applications in
                various domains.
              </p>
            </header>
            <ul className='statistics domain-list'>
              <li className='style1'>
                <img class='tech-icon' src={VRCardboard} />
                {/* <span className='icon fa-vr-cardboard tech-icon'></span> */}
                <strong>AR & VR</strong>{' '}
                <p className='domain-description'>
                  An application to visualize digital collectibles and 3D assets
                  while walking around the showroom.
                </p>
              </li>
              <li className='style2'>
                <img class='tech-icon' src={Blockchain} />
                {/* <span className='icon fa-folder-open-o tech-icon'></span> */}
                <strong>Blockchain</strong>
                <p className='domain-description'>
                  A cryptocurrency wallet that stores over 650+ currencies.
                </p>
              </li>
              <li className='style3'>
                <img class='tech-icon' src={IOT} />
                {/* <span className='icon fa-signal tech-icon'></span> */}
                <strong>IOT</strong>
                <p className='domain-description'>
                  An application to manage a low energy bluetooth device.
                </p>
              </li>
              <li className='style4'>
                <img class='tech-icon' src={Workflow} />
                {/* <span className='icon fa-laptop tech-icon'></span> */}
                <strong>Workflow</strong>
                <p className='domain-description'>
                  A task management tool, content creation, approvals,
                  publishing content to social media.
                </p>
              </li>
              <li className='style5'>
                <img class='tech-icon' src={Chat} />
                {/* <span className='icon fa-diamond tech-icon'></span> */}
                <strong>Communication</strong>
                <p className='domain-description'>
                  A chat application used internally within an organization with
                  features like live streaming, content feed.
                </p>
              </li>
            </ul>

            {/* <p className='content'>
              Nam elementum nisl et mi a commodo porttitor. Morbi sit amet nisl
              eu arcu faucibus hendrerit vel a risus. Nam a orci mi, elementum
              ac arcu sit amet, fermentum pellentesque et purus. Integer maximus
              varius lorem, sed convallis diam accumsan sed. Etiam porttitor
              placerat sapien, sed eleifend a enim pulvinar faucibus semper quis
              ut arcu. Ut non nisl a mollis est efficitur vestibulum. Integer
              eget purus nec nulla mattis et accumsan ut magna libero. Morbi
              auctor iaculis porttitor. Sed ut magna ac risus et hendrerit
              scelerisque. Praesent eleifend lacus in lectus aliquam porta. Cras
              eu ornare dui curabitur lacinia.
            </p> */}
            {/* <footer className='major'>
              <ul className='actions'>
                <li>
                  <Link to='/generic' className='button'>
                    Learn More
                  </Link>
                </li>
              </ul>
            </footer> */}
          </section>
          <div id='contact' />
          {/* <section id='contact' className='main special'>
            <header className='major'>
              <h2>Congue imperdiet</h2>
              <p>
                Donec imperdiet consequat consequat. Suspendisse feugiat congue
                <br />
                posuere. Nulla massa urna, fermentum eget quam aliquet.
              </p>
            </header>
            <footer className='major'>
              <ul className='actions'>
                <li>
                  <Link to='/generic' className='button special'>
                    Get Started
                  </Link>
                </li>
                <li>
                  <Link to='/generic' className='button'>
                    Learn More
                  </Link>
                </li>
              </ul>
            </footer>
          </section> */}
        </div>
        <section id='contact'>
        <Footer onContact={this._showContactForm}/>
        </section>
        {/* <SkyLight dialogStyles={styles.dialogStyles} hideOnOverlayClicked ref={ref => simpleDialog = ref} title="Hi, I'm a simple modal">
  
        </SkyLight> */}
        {this.state.isContactVisible && <Contact closeModal={this._hideContactForm}/>}
      </Layout>
    )
  }
}

export default Index
