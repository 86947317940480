import React from 'react'
import Avtar from '../assets/images/avatar.svg'


const Contact = props => (
    <div class="form-overlay" onClick={(event) => {
        if( event.target !== event.currentTarget ) {
            return;
        }
        props.closeModal()
        event.stopPropagation();
        return true
    }} data-keyboard="false" data-backdrop="static">
    <div class="wrap" data-keyboard="false" data-backdrop="static" onclick={() => {return true}}>
    <form onClick={(event) => {
        if( event.target !== event.currentTarget ) {
            return;
        }
        props.closeModal()
        event.stopPropagation();
        return true
    }} class="form-content" action="https://getform.io/f/c83bd82d-75f2-4739-a675-7c4590441361" method="POST">
        <div class="avatar">
        <img src={Avtar} alt='' />
        </div>
        <div class="detail-form">
        <h2 className="form-title">How can I help you?</h2>
        

        <div class="row-container">
        {renderInput('Name', 'text', 'name')}
        {renderInput('Email', 'email', 'email')}
        </div>
        <div class="full-width">
        {renderMessage('Message', 'message')}
        </div>
        </div>
        <div className='button-container'>
        <button type="submit" class='submit-button'>Submit</button>
        </div>
        </form>
      </div>
      
</div>
)

const renderInput = (title, type, name) => {
    return(
        <div class="input-container is-half">
            <span class="input-name">{title}</span>
            <input className="message" type={type} name={name} required/>
        </div>
    )
}

const renderMessage = (title, name) => {
    return(
        <div class="input-container-description is-full">
            <span class="input-name">{title}</span>
            <textarea rows = "5" name ={name} required/>
            {/* <input className="multiline-message" type="text" name="nom" placeholder="Your name" multiline/> */}
        </div>
    )
}



export default Contact
