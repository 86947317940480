import React from 'react'
import Scrollspy from 'react-scrollspy'
import Scroll from './Scroll'
import AV_LOGO_WHITE from '../assets/images/av_logo_white.png'


const Nav = props => (
  <nav id='nav' className={props.sticky ? 'alt' : ''}>
    <Scrollspy
      items={['about', 'skills', 'work', 'contact']}
      currentClassName='is-active'
      offset={-300}
    >
      {/* {props.sticky && <img
          className='nav-logo'
          src={AV_LOGO_WHITE}
        />} */}
      <li>
        <Scroll type='id' element='about'>
          <a href='#'>About</a>
        </Scroll>
      </li>
      <li>
        <Scroll type='id' element='skills'>
          <a href='#'>Skills</a>
        </Scroll>
      </li>
      <li>
        <Scroll type='id' element='work'>
          <a href='#'>My Work</a>
        </Scroll>
      </li>
      <li>
        <Scroll type='id' element='domains'>
          <a href='#'>Technology</a>
        </Scroll>
      </li>
      <li>
        <Scroll type='id' element='contact'>
          <a href='#'>Contact</a>
        </Scroll>
      </li>
    </Scrollspy>
  </nav>
)

export default Nav
