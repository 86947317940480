import React from 'react'
import Scroll from './Scroll'
import AV_LOGO_WHITE from '../assets/images/av_logo_white.png'

const Header = props => (
  <header id='header' className='alt'>
    <img
          className='site-logo'
          src={AV_LOGO_WHITE}
        />
    <h1>
      Hello, I'm <span className='portfolio-owner'>Arkit Vora</span>
      .
      <br />
      I'm a mobile applications developer.
    </h1>
    <Scroll type='id' element='about'>
      <div className='know-more' align='center'>
        <span>Know More About Me</span>
      </div>
    </Scroll>
  </header>
)

export default Header
