export const SKILL_LEVEL = {
  BEGINNER: 'BEGINNER',
  AVERAGE: 'AVERAGE',
  ADVANCED: 'ADVANCED',
}

export const CATEGORY = {
  MOBILE: 'MOBILE',
  WEB: 'WEB',
  DESIGN: 'DESIGN',
  MISC: 'MISC',
}

export const SKILL_SET = [
  {
    name: 'React Native',
    icon: 'react',
    level: SKILL_LEVEL.ADVANCED,
    showInGraph: true,
    category: CATEGORY.MOBILE,
  },
  {
    name: 'Android',
    icon: 'android-icon',
    level: SKILL_LEVEL.ADVANCED,
    showInGraph: true,
    category: CATEGORY.MOBILE,
  },
  {
    name: 'iOS',
    icon: 'ios',
    level: SKILL_LEVEL.ADVANCED,
    showInGraph: true,
    category: CATEGORY.MOBILE,
  },
  {
    name: 'JavaScript',
    icon: 'javascript',
    level: SKILL_LEVEL.ADVANCED,
    showInGraph: true,
    category: CATEGORY.WEB,
  },
  {
    name: 'React.js',
    icon: 'react',
    level: SKILL_LEVEL.ADVANCED,
    showInGraph: true,
    category: CATEGORY.WEB,
  },
  {
    name: 'HTML5',
    icon: 'html-5',
    level: SKILL_LEVEL.AVERAGE,
    showInGraph: true,
    category: CATEGORY.WEB,
  },
  {
    name: 'CSS3',
    icon: 'css-3',
    level: SKILL_LEVEL.AVERAGE,
    showInGraph: true,
    category: CATEGORY.WEB,
  },
  {
    name: 'TypeScript',
    icon: 'typescript-icon',
    level: SKILL_LEVEL.ADVANCED,
    showInGraph: true,
    category: CATEGORY.WEB,
  },
  {
    name: 'Java',
    icon: 'java',
    level: SKILL_LEVEL.ADVANCED,
    showInGraph: true,
    category: CATEGORY.MOBILE,
  },
  {
    name: 'Swift',
    icon: 'swift',
    level: SKILL_LEVEL.AVERAGE,
    showInGraph: true,
    category: CATEGORY.MOBILE,
  },
  {
    name: 'Node.js',
    icon: 'nodejs',
    level: SKILL_LEVEL.ADVANCED,
    showInGraph: true,
    category: CATEGORY.WEB,
  },
  {
    name: 'Apollo',
    icon: 'apollostack',
    level: SKILL_LEVEL.ADVANCED,
    showInGraph: true,
    category: CATEGORY.MOBILE,
  },
  {
    name: 'Appium',
    icon: 'appium',
    level: SKILL_LEVEL.ADVANCED,
    showInGraph: false,
    category: CATEGORY.MOBILE,
  },
  {
    name: 'Babel',
    icon: 'babel',
    level: SKILL_LEVEL.AVERAGE,
    showInGraph: false,
    category: CATEGORY.WEB,
  },
  {
    name: 'Cocoapods',
    icon: 'cocoapods',
    level: SKILL_LEVEL.ADVANCED,
    showInGraph: false,
    category: CATEGORY.MOBILE,
  },
  {
    name: 'Codepush',
    icon: 'codepush',
    level: SKILL_LEVEL.ADVANCED,
    showInGraph: false,
    category: CATEGORY.MOBILE,
  },
  {
    name: 'D3.js',
    icon: 'd3',
    level: SKILL_LEVEL.AVERAGE,
    showInGraph: false,
    category: CATEGORY.MISC,
  },
  {
    name: 'ESLint',
    icon: 'eslint',
    level: SKILL_LEVEL.ADVANCED,
    showInGraph: false,
    category: CATEGORY.MISC,
  },
  {
    name: 'Ethereum',
    icon: 'ethereum',
    level: SKILL_LEVEL.ADVANCED,
    showInGraph: false,
    category: CATEGORY.MISC,
  },
  {
    name: 'Expo',
    icon: 'expo',
    level: SKILL_LEVEL.ADVANCED,
    showInGraph: false,
    category: CATEGORY.MOBILE,
  },
  {
    name: 'Fabric',
    icon: 'fabric_io',
    level: SKILL_LEVEL.ADVANCED,
    showInGraph: false,
    category: CATEGORY.MOBILE,
  },
  {
    name: 'Docker',
    icon: 'docker-icon',
    level: SKILL_LEVEL.AVERAGE,
    showInGraph: false,
    category: CATEGORY.MISC,
  },
  {
    name: 'Fastlane',
    icon: 'fastlane',
    level: SKILL_LEVEL.ADVANCED,
    showInGraph: false,
    category: CATEGORY.MOBILE,
  },
  {
    name: 'Adobe XD',
    icon: 'adobe-xd',
    level: SKILL_LEVEL.BEGINNER,
    showInGraph: true,
    category: CATEGORY.DESIGN,
  },
  {
    name: 'Sketch',
    icon: 'sketch',
    level: SKILL_LEVEL.BEGINNER,
    showInGraph: true,
    category: CATEGORY.DESIGN,
  },
  {
    name: 'Figma',
    icon: 'figma',
    level: SKILL_LEVEL.ADVANCED,
    showInGraph: false,
    category: CATEGORY.DESIGN,
  },
  {
    name: 'Firebase',
    icon: 'firebase',
    level: SKILL_LEVEL.ADVANCED,
    showInGraph: false,
    category: CATEGORY.MOBILE,
  },
  {
    name: 'Flow',
    icon: 'flow',
    level: SKILL_LEVEL.AVERAGE,
    showInGraph: false,
    category: CATEGORY.MISC,
  },
  {
    name: 'Gatsby',
    icon: 'gatsby',
    level: SKILL_LEVEL.ADVANCED,
    showInGraph: false,
    category: CATEGORY.WEB,
  },
  {
    name: 'Git',
    icon: 'git-icon',
    level: SKILL_LEVEL.ADVANCED,
    showInGraph: false,
    category: CATEGORY.MISC,
  },
  {
    name: 'Google Analytics',
    icon: 'google-analytics',
    level: SKILL_LEVEL.ADVANCED,
    showInGraph: false,
    category: CATEGORY.MISC,
  },
  {
    name: 'Gradle',
    icon: 'gradle',
    level: SKILL_LEVEL.ADVANCED,
    showInGraph: false,
    category: CATEGORY.MOBILE,
  },
  {
    name: 'GraphQL',
    icon: 'graphql',
    level: SKILL_LEVEL.AVERAGE,
    showInGraph: true,
    category: CATEGORY.MISC,
  },
  {
    name: 'Heroku',
    icon: 'heroku-icon',
    level: SKILL_LEVEL.ADVANCED,
    showInGraph: false,
    category: CATEGORY.MISC,
  },
  {
    name: 'Hostgator',
    icon: 'hostgator',
    level: SKILL_LEVEL.ADVANCED,
    showInGraph: false,
    category: CATEGORY.MISC,
  },
  {
    name: 'InVision',
    icon: 'invision',
    level: SKILL_LEVEL.BEGINNER,
    showInGraph: false,
    category: CATEGORY.DESIGN,
  },
  {
    name: 'Jest',
    icon: 'jest',
    level: SKILL_LEVEL.ADVANCED,
    showInGraph: false,
    category: CATEGORY.MISC,
  },
  {
    name: 'Jira',
    icon: 'jira',
    level: SKILL_LEVEL.ADVANCED,
    showInGraph: false,
    category: CATEGORY.MISC,
  },
  {
    name: 'JSON',
    icon: 'json',
    level: SKILL_LEVEL.ADVANCED,
    showInGraph: false,
    category: CATEGORY.MISC,
  },
  {
    name: 'Material UI',
    icon: 'material-ui',
    level: SKILL_LEVEL.BEGINNER,
    showInGraph: false,
    category: CATEGORY.DESIGN,
  },
  {
    name: 'MobX',
    icon: 'mobx',
    level: SKILL_LEVEL.ADVANCED,
    showInGraph: false,
    category: CATEGORY.MISC,
  },
  {
    name: 'NPM',
    icon: 'npm',
    level: SKILL_LEVEL.ADVANCED,
    showInGraph: false,
    category: CATEGORY.WEB,
  },
  {
    name: 'Postman',
    icon: 'postman',
    level: SKILL_LEVEL.ADVANCED,
    showInGraph: false,
    category: CATEGORY.MISC,
  },
  {
    name: 'Prettier',
    icon: 'prettier',
    level: SKILL_LEVEL.BEGINNER,
    showInGraph: false,
    category: CATEGORY.MISC,
  },
  {
    name: 'React Router',
    icon: 'react-router',
    level: SKILL_LEVEL.AVERAGE,
    showInGraph: false,
    category: CATEGORY.WEB,
  },
  {
    name: 'Redux Saga',
    icon: 'redux-saga',
    level: SKILL_LEVEL.ADVANCED,
    showInGraph: false,
    category: CATEGORY.MISC,
  },
  {
    name: 'Storybook',
    icon: 'storybook-icon',
    level: SKILL_LEVEL.ADVANCED,
    showInGraph: false,
    category: CATEGORY.MISC,
  },
  {
    name: 'Unity',
    icon: 'unity',
    level: SKILL_LEVEL.BEGINNER,
    showInGraph: true,
    category: CATEGORY.MISC,
  },
  {
    name: 'Webflow',
    icon: 'webflow',
    level: SKILL_LEVEL.ADVANCED,
    showInGraph: false,
    category: CATEGORY.WEB,
  },
  {
    name: 'Webpack',
    icon: 'webpack',
    level: SKILL_LEVEL.AVERAGE,
    showInGraph: false,
    category: CATEGORY.WEB,
  },
  {
    name: 'WooCommerce',
    icon: 'woocommerce',
    level: SKILL_LEVEL.AVERAGE,
    showInGraph: false,
    category: CATEGORY.WEB,
  },
  {
    name: 'Wordpress',
    icon: 'wordpress-icon',
    level: SKILL_LEVEL.ADVANCED,
    showInGraph: false,
    category: CATEGORY.WEB,
  },
  {
    name: 'Yarn',
    icon: 'yarn',
    level: SKILL_LEVEL.ADVANCED,
    showInGraph: false,
    category: CATEGORY.WEB,
  },
  {
    name: 'Cpanel',
    icon: 'cpanel',
    level: SKILL_LEVEL.AVERAGE,
    showInGraph: false,
    category: CATEGORY.MISC,
  },
  {
    name: 'Zeplin',
    icon: 'zeplin',
    level: SKILL_LEVEL.ADVANCED,
    showInGraph: false,
    category: CATEGORY.DESIGN,
  },
]

export const SKILLS_BY_COMPANY = {
  DEKORATE: ['Android', 'iOS', 'React', 'Javascript', 'Node.js'],
  SPRINKLR: ['Android', 'iOS', 'React Native', 'React', 'Javascript', 'IOT'],
  ECOMI: [
    'Android',
    'iOS',
    'React Native',
    'React',
    'Javascript',
    'Typescript',
    'GraphQL',
    'IOT',
  ],
}
